import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <p>{`To start using it just add `}<inlineCode parentName="p">{`select`}</inlineCode>{` class on select HTML element.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='select'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <select className='select display-block' name='select' id='select' defaultValue=''>
        <option value="" disabled placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='select'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <select
        class='select display-block'
        name='select'
        id='select'
      >
        <option value="" disabled selected placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "labeled"
    }}>{`Labeled`}</h2>
    <p>{`Place a label or button in any side of a select. You may also place on both sides. Inside `}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` just add a label or button with `}<inlineCode parentName="p">{`input-group-text`}</inlineCode>{`.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='select'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <span className='input-group-text'>
        @
      </span>
      <select className='select display-block' name='select' id='select' defaultValue=''>
        <option value="" disabled placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='select'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <span
        class='input-group-text'
      >
        @
      </span>
      <select
        class='select display-block'
        name='select'
        id='select'
      >
        <option value="" disabled selected placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "error"
    }}>{`Error`}</h2>
    <p>{`Select field can show the data contains errors. Add `}<inlineCode parentName="p">{`error`}</inlineCode>{` class on input and a element with `}<inlineCode parentName="p">{`box-validation box-invalid`}</inlineCode>{` below \``}<inlineCode parentName="p">{`wrapper-input`}</inlineCode>{` in case of error message is need it.`}</p>
  <div className='col-sm-24 col-md-12 col-lg-8'>
    <label className='display-block' htmlFor='select'>
      Label
    </label>
    <div className='wrapper-input'>
      <select className='select display-block error' name='select-error' id='select-error' defaultValue=''>
        <option value="" disabled placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
    <div className='box-validation box-invalid show mb-3'>
      <i className="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='select'>
      Label
    </label>
    <div class='wrapper-input'>
      <select
        class='select display-block error'
        name='select-error'
        id='select-error'
      >
        <option value="" disabled selected placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
    <div class='box-validation box-invalid show mb-3'>
      <i class="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Select field can show that it is disabled. Add `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute/class on it.`}</p>
    <div className='col-sm-24 col-md-12 col-lg-8'>
  <label className='display-block' htmlFor='select'>
    Label
  </label>
  <div className='wrapper-input mb-3'>
    <select className='select display-block' name='select-disabled' id='select-disabled' defaultValue='' disabled>
      <option value="" disabled placeholder>Select something</option>
      <option value="1">Option 1</option>
      <option value="2">Option 2</option>
      <option value="3">Option 3</option>
    </select>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`  <div class='col-24'>
    <label class='display-block' for='select'>
      Label
    </label>
    <div class='wrapper-input mb-3'>
      <select
        class='select display-block'
        name='select-disabled'
        id='select-disabled'
        disabled
      >
        <option value="" disabled selected placeholder>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      